

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { colors } from '../../constants/colors';
import { imgs } from '../../constants/images';
// import { amplitudeTrack } from '../../utils/amplitude/amplitude';

const logoStyle = {
  width: '65px',
  height: '65px',
  cursor: 'pointer',
  borderRadius: '50%',
};

export default function Header() {
  const [open, setOpen] = useState<boolean>(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const menu = [
    {
      id: 1, label: 'ผ่อนสินค้า',
      link: 'https://www.ufriend.shop/%e0%b8%9c%e0%b9%88%e0%b8%ad%e0%b8%99%e0%b8%aa%e0%b8%b4%e0%b8%99%e0%b8%84%e0%b9%89%e0%b8%b2/',
      // key: 'H_Header_O_lease'
    },
    {
      id: 2, label: 'ออมดาวน์',
      link: 'https://www.ufriend.shop/%e0%b8%ad%e0%b8%ad%e0%b8%a1%e0%b8%94%e0%b8%b2%e0%b8%a7%e0%b8%99%e0%b9%8c/',
      // key: 'H_Header_O_deposit'
    },
    {
      id: 3, label: 'ไอโฟนแลกเงิน',
      link: 'https://www.ufriend.shop/%e0%b9%84%e0%b8%ad%e0%b9%82%e0%b8%9f%e0%b8%99%e0%b9%81%e0%b8%a5%e0%b8%81%e0%b9%80%e0%b8%87%e0%b8%b4%e0%b8%99/',
      // key: 'H_Header_O_refin'
    },
    {
      id: 4, label: 'สินค้าของเรา',
      link: 'https://www.ufriend.shop/shop/',
      // key: 'H_Header_O_ourproduct'
    },
    {
      id: 5, label: 'เกี่ยวกับยูเฟรนด์',
      link: 'https://www.ufriend.shop/%e0%b9%80%e0%b8%81%e0%b8%b5%e0%b9%88%e0%b8%a2%e0%b8%a7%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b8%a2%e0%b8%b9%e0%b9%80%e0%b8%9f%e0%b8%a3%e0%b8%99%e0%b8%94%e0%b9%8c/',
      // key: 'H_Header_O_ufriend'
    },
    {
      id: 6, label: 'บทความ',
      link: 'https://www.ufriend.shop/%e0%b8%9a%e0%b8%97%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1/',
      // key: 'H_Header_O_topic'
    },
    {
      id: 7, label: 'สาขาใกล้คุณ',
      link: '/near-branch',
      // key: 'H_Header_O_branch'
    },
    {
      id: 8, label: 'ร่วมงานกับเรา',
      link: 'https://www.ufriend.shop/%e0%b8%a3%e0%b9%88%e0%b8%a7%e0%b8%a1%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b9%80%e0%b8%a3%e0%b8%b2-new/',
      // key: 'H_Header_O_career'
    },
  ]

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          maxHeight: '100%',
          maxWidth: '100%',
          minHeight: 70
        }}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: "relative",
            flexShrink: 0,
            // borderRadius: '999px',
            bgcolor:
              theme.palette.mode === 'light'
                ? 'rgba(255, 255, 255, 0.4)'
                : 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(24px)',
            maxHeight: 80,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              px: 0,
            }}
          >
            <img
              src={imgs.newLogo}
              style={logoStyle}
              alt="Ufriend"
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              {
                menu.map((item: any, index: number) => {
                  return (
                    <MenuItem
                      sx={{ py: '6px', px: '12px' }}
                      key={index}
                      // onClick={() => { amplitudeTrack(item.key) }}
                    >
                      <Link variant="body2" color={colors.black} href={item.link}>
                        {item.label}
                      </Link>
                    </MenuItem>
                  )
                })
              }
            </Box>
          </Box>


          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px', position: 'absolute', right: 10, top: "50%", transform: "translateY(-50%)" }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                >
                </Box>
                {
                  menu.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        sx={{ py: '6px', px: '12px' }}
                        key={index}
                        // onClick={() => { amplitudeTrack(item.key) }}
                      >
                        <Link variant="body2" color={colors.black}  href={item.link}>
                          {item.label}
                        </Link>
                      </MenuItem>
                    )
                  })
                }
                <Divider />
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </div >
  );
}