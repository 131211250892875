import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { Row } from 'react-bootstrap';
import HelperText from '../input/helperText';
import { colors } from '../../constants/colors';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useRef } from 'react';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export const UploadFileContainer = styled(Row)(() => ({
  border: `1px dashed ${colors.blue}`,
  margin: '0px 3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}))

type UploadImageProps = {
  onUpload?: (event: any) => void
  helperText?: any;
  loading?: boolean | false
  ref?: any
}

export default function UploadImage(props: UploadImageProps) {
  return (
    <>
      <UploadFileContainer>
        <LoadingButton
          component="label"
          role={undefined}
          variant="text"
          startIcon={<CloudUploadOutlinedIcon />}
          tabIndex={-1}
          loading={props.loading}
          loadingIndicator="กำลังอัพโหลด..."
        // loadingPosition="start"
        >
          อัพโหลด
          <VisuallyHiddenInput ref={props.ref} onClick={(event) => {
            (event.target as HTMLInputElement).value = '';
          }}
            type="file" accept="image/png, image/jpeg , image/jpg ,application/pdf" onChange={(event: any) => { props.onUpload && props.onUpload(event) }} />
        </LoadingButton>
      </UploadFileContainer>
      {props.helperText && (
        <div className='pt-2'>
          <HelperText label={props.helperText} />
        </div>
      )}
    </>
  )
}
