import {
  Route,
  BrowserRouter as Router,
  Switch, Redirect
} from "react-router-dom";
import './App.css';
import { ToastContainer } from "react-toastify";
import Header from "./components/header/header";
import routes from "./routes/router";
import { styled } from '@mui/material/styles'

import React, { useEffect } from "react";
import Footer from "./components/footer/footer";
import * as amplitude from "@amplitude/analytics-browser";

const ContainerComponent = styled('div')(() => ({
  paddingLeft: '10px',
  paddingRight: '10px',
  '&.no-appBar': {
    top: '0 !important',
    // height: '100vh !important'
  }
}))

const ContainerContent = styled("div")(() => ({
  width: "100%",
  "> div": {
    width: "100% !important",
    display: "block !important",
    minHeight: "unset !important",
    border: "none !important",
    boxShadow: "none important",
    marginTop: "0 !important",
  },
}));


export function App() {

  function PrivateRoute({ exact, path, key, component: Component }: any) {
    let navigate = path
    return <Route exact={exact} path={navigate} key={key}>{Component}</Route>
  }
  const key = process.env.REACT_APP_AMPLITUDE_KEY || ''
  
  useEffect(() => {
    amplitude.init(key, {
      defaultTracking: {
        attribution: false,
        pageViews: false, //[Amplitude] Page Viewed
        // sessions: false, //[Amplitude] Start Session
        formInteractions: false, //[Amplitude] Form Submitted
        fileDownloads: false,
      },
    });
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path={'/'}>
            <Redirect to="/register" />
          </Route>
          {routes.map(({ name, path, component, key }, idx) => (
            <PrivateRoute exact path={path} key={name || idx} keyName={key} component={
              <>
                <Header />
                <ContainerComponent className={`div-component d-flex flex-column no-appBar`}>
                  <ContainerContent>
                    {component && React.createElement(component)}
                  </ContainerContent>
                </ContainerComponent>
                <Footer />
              </>
            } />
          ))}
          <Route path="*">
            <Redirect to="/register" />
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}

