import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AuthenApi from '../../../api/authen/authen.api';
import ButtonCustom from '../../../components/button/buttonCustom';
import InputTextField from '../../../components/input/inputTextField';
import Loading from '../../../components/loading/loading';
import { swalError } from '../../../components/notification/swal';
import AutocompleteSelect from '../../../components/select/autoCompleteSelect';
import { checkPattenPhoneNumber, checkThaiLang } from '../../../utils/common';
import { optionsPrefix } from '../../../utils/options';
import Contact from '../componant/contact';
import * as Styles from './layout-register.style';
import { amplitudeTrack } from '../../../utils/amplitude/amplitude';
import { getDeviceId, getUserId, setUserId } from '@amplitude/analytics-browser';

const initStateErrorMessage = {
    REGISTER_PREFIX: ``,
    REGISTER_OTHERPREFIX: ``,
    REGISTER_NAME: ``,
    REGISTER_SURNAME: ``,
    REGISTER_MOBILE: ``,
    REGISTER_ZIPCODE: ``,
};

interface props {
    data: any
    onClickNext: () => void
    onChange?: (value: any) => void
    optionMaster: any
}
export default function LayoutRegister(props: props) {
    const userId = getUserId();
    const deviceId = getDeviceId()
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
    const [data, setData] = useState<any>(props.data);
    const [loading, setLoading] = useState(false);

    const [type, setType] = useState<string>('')
    const [prefix, setPrefix] = useState<string>('')
    const [prefixOther, setPrefixOther] = useState<string>(props.data?.profile?.prefix || '')

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [zipCode, setZipcode] = useState<string>('')
    const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false)
    const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false)

    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const PARAMS_CHATCONE_TOKEN = params.get('token');
    const PARAMS_CHATCONE_SOCIAL_ID = params.get('id');
    const PARAMS_CHATCONE_CHANNEL_ID = params.get('channel_id');
    const PARAMS_WALKIN = params.get('walkin');
    const PARAMS_PLATFORM = params.get('platform');
    const PARAMS_TYPE = params.get('type');
    const PARAMS_FORE_NAME = params.get('foreName');
    const PARAMS_SUR_NAME = params.get('surName');
    const PARAMS_PHONE = params.get('phone');
    const PARAMS_PREFIX_WORD_PRESS = params.get('prefix');
    const PARAMS_ZIP_CODE_WORD_PRESS = params.get('zipCode');
    const [auto, setAuto] = useState<boolean>(false)

    useEffect(() => {
        setType(PARAMS_TYPE === 'DOWNPAYMENT' ? 'INSTALLMENT' : PARAMS_TYPE || '')
        setFirstName(PARAMS_FORE_NAME || '')
        setLastName(PARAMS_SUR_NAME || '')
        setPhoneNumber(PARAMS_PHONE || '')
        setPrefix(PARAMS_PREFIX_WORD_PRESS || '')
        setZipcode(PARAMS_ZIP_CODE_WORD_PRESS || '')
        if (PARAMS_TYPE !== '' && PARAMS_SUR_NAME !== '' && PARAMS_PHONE !== '' && PARAMS_PREFIX_WORD_PRESS !== '' && PARAMS_ZIP_CODE_WORD_PRESS) {
            setAuto(true)
        }
    }, [PARAMS_TYPE, PARAMS_FORE_NAME, PARAMS_SUR_NAME, PARAMS_PHONE, PARAMS_PREFIX_WORD_PRESS, PARAMS_ZIP_CODE_WORD_PRESS]);

    useEffect(() => {
        if (auto && type !== '' && prefix !== '' && firstName !== '' && lastName !== '' && phoneNumber !== '' && zipCode !== '' && props.optionMaster.length > 0) {
            onSubmit()
        }
    }, [type, prefix, firstName, lastName, phoneNumber, zipCode, props.optionMaster]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(data)
        }
    }, [data])

    useEffect(() => {
        if (phoneNumber && phoneNumber.length === 10 && PARAMS_PHONE !== '') {
            const check = checkPattenPhoneNumber(phoneNumber)
            setCheckPhonenumber(!check)
            setCheckDigiPhonenumber(false)
        }
    }, [phoneNumber]);

    const onSubmit = async () => {
        const dataZipCode = props.optionMaster?.filter((val: any) => val.zipcode === zipCode)

        if (!prefix || !firstName || !lastName || !phoneNumber || !zipCode ||
            checkPhoneNumber || checkDigiPhoneNumber || (prefix === 'อื่นๆ' && !prefixOther) ||
            phoneNumber.length !== 10 || zipCode.length !== 5 || !checkThaiLang(firstName) || !checkThaiLang(lastName) ||
            dataZipCode.length === 0) {
            const msg: any = {
                REGISTER_PREFIX: !prefix ? 'โปรดเลือกคำนำหน้าชื่อ' : '',
                REGISTER_NAME: !firstName ? 'โปรดกรอกชื่อ' : !checkThaiLang(firstName) ? 'โปรดกรอกชื่อภาษาไทย' : '',
                REGISTER_SURNAME: !lastName ? 'โปรดกรอกนามสกุล' : !checkThaiLang(lastName) ? 'โปรดกรอกนามสกุลภาษาไทย' : '',
                REGISTER_MOBILE: !phoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : phoneNumber.length !== 10 ? 'ระบุจำนวน 10 ตัวอักษร' : '',
                REGISTER_ZIPCODE: !zipCode ? 'โปรดกรอกรหัสไปรษณีย์' : dataZipCode.length === 0 ? 'รหัสไปรษณีย์ไม่ถูกต้อง' : zipCode.length !== 5 ? 'รหัสไปรษณีย์ไม่ถูกต้อง' : '',
                REGISTER_OTHERPREFIX: !prefixOther ? 'โปรดกรอกคำนำหน้าชื่อ' : ''
            }
            setErrorMessage({ ...initStateErrorMessage, ...msg })
            setLoading(false)
            setAuto(false)
            return false
        } else {
            let id = deviceId
            setUserId(id || '')
            setLoading(true)
            const payload = {
                type: type,
                prefix: prefix,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                zipCode: zipCode,
                amplitudeId: id || '',
                chatcone:
                    (PARAMS_CHATCONE_SOCIAL_ID || PARAMS_CHATCONE_TOKEN || PARAMS_CHATCONE_CHANNEL_ID) ?
                        {
                            social_id: PARAMS_CHATCONE_SOCIAL_ID || null,
                            token: PARAMS_CHATCONE_TOKEN || null,
                            channel_id: PARAMS_CHATCONE_CHANNEL_ID || null
                        } : {},
                platform: PARAMS_PLATFORM ? PARAMS_PLATFORM : null,
                walkin: PARAMS_WALKIN === 'true' ? true : false,
            }
            setData({
                ...props.data, type: type,
                profile: {
                    ...data.profile,
                    type: type,
                    prefix: prefixOther != '' ? prefixOther : prefix,
                    firstName: firstName,
                    lastName: lastName,
                    zipcode: zipCode,
                    phoneNumber: phoneNumber
                },
                walkin: PARAMS_WALKIN === 'true' ? true : false,
                chatcone:
                    (PARAMS_CHATCONE_SOCIAL_ID || PARAMS_CHATCONE_TOKEN || PARAMS_CHATCONE_CHANNEL_ID) ?
                        {
                            social_id: PARAMS_CHATCONE_SOCIAL_ID || null,
                            token: PARAMS_CHATCONE_TOKEN || null,
                            channel_id: PARAMS_CHATCONE_CHANNEL_ID || null
                        } : {},
                platform: PARAMS_PLATFORM ? PARAMS_PLATFORM : null,
                amplitudeId: id || ''
            })
            await AuthenApi.registerCustomer(payload).then((e) => {
                setLoading(false)
                setAuto(false)
                console.log(PARAMS_TYPE)
                if (PARAMS_TYPE) {
                    if (type === 'INSTALLMENT') {
                        amplitudeTrack('0_Start_Home_lease')
                    } else {
                        amplitudeTrack('0_Start_Home_refin')
                    }
                } else {
                    if (type === 'INSTALLMENT') {
                        amplitudeTrack('0_Start_Register_lease')
                    } else {
                        amplitudeTrack('0_Start_Register_refin')
                    }
                }
                if (props.onClickNext) props.onClickNext()
            }).catch((e: any) => {
                setLoading(false)
                setAuto(false)
                return swalError(e.response.data.message || 'ระบบมีปัญหากรุณาลองใหม่อีกครั้ง')
            })
        }
    }
    useEffect(() => {
        if (props.onChange) {
            props.onChange(data);
        }
    }, [data]);

    return (
        <Styles.Container>
            <Loading show={loading} type="fullLoading" />
            <div className='pt-5 font-26 font-extra-bold text-center pb-2'>
                สนใจลงทะเบียน
            </div>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={type}
                onChange={(e: any) => setType(e.target.value)}
                className='justify-content-center'
            >
                <FormControlLabel
                    value="INSTALLMENT"
                    control={<Radio
                        sx={(theme) => ({
                            '& .MuiSvgIcon-root': {
                                [theme.breakpoints.down('xl')]: {
                                    fontSize: 24,
                                },
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: 24,
                                },
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 30,
                                }
                            },
                        })}
                    />}
                    label={<div className='font-22 font-extra-bold'>
                        ผ่อนสินค้า
                    </div>}
                />
                <FormControlLabel
                    value="REFINANCE"
                    control={<Radio
                        sx={(theme) => ({
                            '& .MuiSvgIcon-root': {
                                [theme.breakpoints.down('xl')]: {
                                    fontSize: 24,
                                },
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: 24,
                                },
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 30,
                                }
                            },
                        })}
                        disabled={PARAMS_TYPE === 'DOWNPAYMENT'}
                    />}
                    label={<div className='font-22 font-extra-bold'>
                        ไอโฟนแลกเงิน
                    </div>}
                />

            </RadioGroup>
            <Styles.MarginRow>
                <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>

                    <AutocompleteSelect
                        options={optionsPrefix || []}
                        value={_.find(optionsPrefix, (val: any) => val.value === prefix) || null}
                        placeholder={'เลือกคำนำหน้าชื่อ'}
                        getOptionLabel={(option: any) => option.label}
                        labelId="contracttype"
                        onChange={(event, value, reason) => {
                            if (value) {
                                if (value.value !== 'อื่นๆ') {
                                    setPrefixOther('')
                                    setErrorMessage({ ...errorMessage, REGISTER_PREFIX: '', REGISTER_OTHERPREFIX: '' })
                                    setPrefix(value.value)
                                } else {
                                    setErrorMessage({ ...errorMessage, REGISTER_PREFIX: '' })
                                    setPrefix(value.value)
                                }

                            } else if (reason === 'clear') (
                                setPrefix('')
                            )
                        }}
                        required
                        helperText={errorMessage.REGISTER_PREFIX}
                        heading='คำนำหน้าชื่อ'
                        key={'prefix-1'}
                    />
                </Col>
                {prefix === 'อื่นๆ' && (
                    <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>
                        <InputTextField
                            value={prefixOther || ''}
                            onchange={(event: any) => {
                                setErrorMessage({ ...errorMessage, REGISTER_OTHERPREFIX: '' })
                                setPrefixOther(event.target.value)
                            }}
                            helperText={errorMessage.REGISTER_OTHERPREFIX}
                            heading='โปรดระบุ'
                            placeholder='กรอกคำนำหน้าชื่อ'
                            required
                        />
                    </Col>
                )}
                <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2' >
                    <InputTextField
                        placeholder={'กรอกชื่อ'}
                        value={firstName || ''}
                        onchange={(event: any) => {
                            setErrorMessage({ ...errorMessage, REGISTER_NAME: '' })
                            if (checkThaiLang(event.target.value)) {
                                setFirstName(event.target.value)
                            } else if (!checkThaiLang(event.target.value)) {
                                setFirstName(event.target.value)
                                setErrorMessage({ ...errorMessage, REGISTER_NAME: 'โปรดกรอกชื่อภาษาไทย' })
                            } else if (event.target.value === '') {
                                setFirstName('')
                            }
                        }}
                        helperText={errorMessage.REGISTER_NAME}
                        heading='ชื่อ (ภาษาไทย)'
                        // onBlur={() => {
                        //     if (firstName !== '')
                        //         amplitudeTrack('0_Register_P_firstname', firstName)
                        // }}
                        required
                    />
                </Col>
                <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>
                    <InputTextField
                        placeholder={'กรอกนามสกุล'}
                        value={lastName || ''}
                        onchange={(event: any) => {
                            setErrorMessage({ ...errorMessage, REGISTER_SURNAME: '' })
                            if (checkThaiLang(event.target.value)) {
                                setLastName(event.target.value)
                            } else if (!checkThaiLang(event.target.value)) {
                                setLastName(event.target.value)
                                setErrorMessage({ ...errorMessage, REGISTER_SURNAME: 'โปรดกรอกนามสกุลภาษาไทย' })
                            } else if (event.target.value === '') {
                                setLastName('')
                            }
                        }}
                        helperText={errorMessage.REGISTER_SURNAME}
                        heading='นามสกุล (ภาษาไทย)'
                        // onBlur={() => {
                        //     if (lastName !== '')
                        //         amplitudeTrack('0_Register_P_lastname', lastName)
                        // }}
                        required
                    />
                </Col>
            </Styles.MarginRow>

            <Styles.MarginRow>
                <Col xs={12} md={4} className='pt-2'>
                    <InputTextField
                        placeholder={'กรอกเบอร์โทรศัพท์'}
                        value={phoneNumber || ''}
                        onchange={(event: any) => {
                            if (event.target.value.length <= 10) {
                                setPhoneNumber(event.target.value)
                                setErrorMessage({ ...errorMessage, REGISTER_MOBILE: '' })
                            }
                        }}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        helperText={errorMessage.REGISTER_MOBILE}
                        heading='เบอร์โทรศัพท์'
                        required
                    // onBlur={() => {
                    //     if (phoneNumber !== '')
                    //         amplitudeTrack('0_Register_P_tel', phoneNumber)
                    // }}
                    />
                </Col>
                <Col xs={12} md={4} className='pt-2'>
                    <InputTextField
                        placeholder={'กรอกรหัสไปรษณีย์ที่อยู่ปัจจุบัน'}
                        value={zipCode || ''}
                        onchange={(event: any) => {
                            if (event.target.value.length <= 5) {
                                setErrorMessage({ ...errorMessage, REGISTER_ZIPCODE: '' })
                                setZipcode(event.target.value)
                            }
                        }}
                        type='number'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        helperText={errorMessage.REGISTER_ZIPCODE}
                        heading='รหัสไปรษณีย์ที่อยู่ปัจจุบัน'
                        required
                    />
                </Col>
            </Styles.MarginRow>
            <Styles.CenterContainer>
                <ButtonCustom
                    btnStyle={{ width: "100%", alignSelf: "center" }}
                    style={{ marginTop: 2 }}
                    textButton={"ยืนยัน"}
                    onClick={() => {
                        onSubmit();
                    }}
                />
            </Styles.CenterContainer>

            <Styles.CenterContainer>
                <Styles.ContactBox my={7}>
                    <Contact titleName='ติดต่อสอบถาม' />
                </Styles.ContactBox>
            </Styles.CenterContainer>
        </Styles.Container>
    )
}
